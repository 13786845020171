import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './Download.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CheckoutForm = ({ onSuccess, calendarRef, framePrice }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // メールアドレスを取得
    const email = event.target.email.value;
    console.log(email);
    // メールアドレスがなかったら、エラーを表示
    if (email === '') {
      setError('メールアドレスを入力してください。');
      setProcessing(false);
      return;
    }
    
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setError(null);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    } else {
      // ここでバックエンドAPIに支払い情報を送信
      try {
        const cssResponse = await fetch('/css/Calendar.css');
        const cssContent = await cssResponse.text();

        const baseURL = process.env.REACT_APP_API_URL || '';
        // カレンダーのクローンを作成
        const clone = calendarRef.current.cloneNode(true);

        // クローン内のすべての画像を取得
        const images = clone.getElementsByTagName('img');

        // 画像のsrcをデータURLに変換
        for (let img of images) {
          if (img.src) {
            const response = await fetch(img.src);
            const blob = await response.blob();
            const reader = new FileReader();
            await new Promise((resolve) => {
              reader.onloadend = () => {
                img.src = reader.result;
                resolve();
              };
              reader.readAsDataURL(blob);
            });
          }
        }

        // Calendar.cssの内容をcssTextに追加
        const cssText = `
          ${cssContent}
          /* 他の必要なスタイルがあれば追記 */
        `;

        // HTMLコンテンツを作成（1年分のカレンダーを含む）
        const htmlContent = `
          <html>
            <meta>
              <link href="https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100..900&display=swap" rel="stylesheet">
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/destyle.css@1.0.15/destyle.css" />
            </meta>
            <head>
              <style>${cssText}</style>
            </head>
            <body>
              ${clone.outerHTML}
            </body>
          </html>
        `;

        const response = await fetch(`${baseURL}/payment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethod.id,
            email: email,
            htmlContent: htmlContent,
            amount: 500 + framePrice,
          }),
        });
        
        if (response.ok) {
          onSuccess();
        } else {
          setError('決済に失敗しました。');
        }
      } catch (err) {
        setError('エラーが発生しました。');
      }
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="download-mail">PDFを送信したいメールアドレス：<br />
        <input type="text" name="email" placeholder="coromi@seeda.jp" className="download-mail-input" />
      </p>
      <div className="card-element-container">
        {/* CardElementに郵便番号収集を無効にするオプションを追加 */}
        <CardElement 
          options={{ 
            hidePostalCode: true,
            style: { 
              base: { 
                fontFamily: '"M PLUS 1", sans-serif',
              }
            }
          }} 
        />
      </div>
      
      <p className="termlead"><a href="https://seeda.fun/terms/coromi_terms/" className="otherlink" target="_blank" rel="noreferrer">利用規約</a>に同意してPDFを取得する</p>
      <button
        type="submit"
        className="download-button"
        disabled={!stripe || processing}
      >
        {processing ? '処理中...' : 'メールに送信する'}
      </button>
      {error && <div className="error-message">{error}</div>}
    </form>
  );
};

function Download({ calendarRef, framePrice }) {
  const [, setIsDownloaded] = useState(false);
  const options = {
    fonts: [{
      cssSrc: "https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100..900&display=swap"
    }]
  }

  const handlePaymentSuccess = () => {
    setIsDownloaded(true);
    // ここでダウンロード処理を実行
  };

  return (
    <div className="download">
      <h3 className="h3 main-f-color">カレンダーをダウンロード</h3>
      <p className="lead">アメリカン・エキスプレス、中国銀聯 (CUP)、ディスカバーとダイナースクラブ、eftpos オーストラリア、JCB、Mastercard、Visaのクレジットカードが利用可能です。</p>

      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm onSuccess={handlePaymentSuccess} calendarRef={calendarRef} framePrice={framePrice} />
      </Elements>
    </div>
  );
}

export default Download;